<template>
  <v-data-table :headers="headers" :items="desserts" class="ma-2 elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>ЦВЕТЫ</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >Новый</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="editedItem.name"
                  label="Название"
                ></v-text-field>
                <v-select
                  :items="flowerOptions"
                  v-model="editedItem.flower_category_id"
                  item-text="name"
                  item-value="id"
                  label="Категория цветов"
                />
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
              <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Обновить</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FlowersTab",

  data: () => ({
    inputName: "",
    dialog: false,
    headers: [
      {
        text: "Категория",
        align: "start",
        value: "category"
      },
      {
        text: "Название",
        align: "start",
        value: "name"
      },
      { text: "Действия", value: "actions", sortable: false, width: 100 }
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      flower_category_id: ""
    },
    defaultItem: {
      name: "",
      flower_category_id: ""
    }
  }),

  computed: {
    ...mapGetters(["allFlowersData"]),
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
    desserts() {
      let arr = [];
      if (Object.keys(this.allFlowersData).length === 0) return arr;
      this.allFlowersData.forEach(e =>
        arr.push({
          id: e.id,
          name: e.name,
          flower_category_id: e.flower_category_id,
          category: e.flower_category ? e.flower_category.name : ""
        })
      );
      return arr;
    },
    flowerOptions() {
      return this.$store.state.flowerCategory.flowerCategoryData;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions([
      "getFlowersData",
      "getFlowerCategoryData",
      "destroyFlowerData",
      "createFlowerData",
      "pushFlowerData"
    ]),
    initialize() {
      this.getFlowersData();
      this.getFlowerCategoryData();
    },
    save() {
      if (this.editedIndex > -1) {
        this.pushFlowerData({
          id: this.editedItem.id,
          name: this.editedItem.name,
          flower_category_id: this.editedItem.flower_category_id
        });
      } else {
        this.createFlowerData({
          name: this.editedItem.name,
          flower_category_id: this.editedItem.flower_category_id
        });
      }
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  }
};
</script>

<style scoped></style>
